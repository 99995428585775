import * as React from "react"
import { useFormik } from "formik"
import {
  addUtmTagsInUrls,
  getUtmFromCookie,
  setCookieFromUrl,
} from "../widgets/cookie"
import { navigate } from "gatsby"
import InfoIcon from "../img/info-icon.svg"

//contact up page which asks for first_name, last_name,phone_number, email, notes and utm tags from cookie as landing_url_params (Ex. {utm_campaign:'sandesh', utm_source:'instagram'})
//validation using formik form
//validation on first_name is minimum 3 words and maximum 64
//validation on last_name is minimum 3 words and maximum 64
//validation on phone_number is valid Indian phone number
//validation on email is valid email
//validation on notes is minimum 3 words and maximum 500
//validation on landing_url_params is valid javascript object (Ex. {utm_campaign:'sandesh', utm_source:'instagram'})
//if any validation fails then show error message
//on submit call api https://hybrid.opareviews.com/c/contact-us with body {first_name, last_name,phone_number, email, notes, landing_url_params, employee_range[1-50,50-200,200+], company_type[FMCG,Cosmetics,Skin Care,Hair Care,Fashion,Accessories,Fitness,Health & Wellness,Food,Hygiene,Kids & Home,Electronics,Apps]}
//if api call fails then show error notification "Something went wrong"
//if api call succeeds then show success component and give option to go to home page and fill form again
export default function LongLandingContactUs(props: any) {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      notes: "",
      landing_url_params: getUtmFromCookie(),
      company_type: "",
    },
    onSubmit: (values) => {
      if (values.company_type === "influencer") {
        window.location.href = "/influencers"
        return
      }
      setLoading(true)
      fetch("https://hybrid.opareviews.com/c/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...values,
          phone_number: "+91" + values.phone_number,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (values.company_type === "brand") {
            //push to thank you page
            navigate("/thank-you")
          } else navigate("/")
          if (res.message === "OK") {
            setSuccess(true)
            setLoading(false)
          } else {
            setError(true)
            setLoading(false)
          }
        })
        .catch((err) => {
          setError(true)
          setLoading(false)
        })
    },
    validate: (values) => {
      //validate field only if it is touched by user
      // validate all form  fields  if submit button is clicked
      //trim values before validating

      const errors: any = {}
      if (
        (values.first_name.trim().length < 3 ||
          values.first_name.trim().length > 64) &&
        (formik.touched.first_name || formik.isSubmitting)
      ) {
        errors.first_name = "First name should be between 3-64 characters"
      }
      if (
        (values.last_name.trim().length < 3 ||
          values.last_name.trim().length > 64) &&
        (formik.touched.last_name || formik.isSubmitting)
      ) {
        errors.last_name = "Last name should be between 3-64 characters"
      }
      if (
        !values.phone_number.trim().match(/^\d{10}$/) &&
        (formik.touched.phone_number || formik.isSubmitting)
      ) {
        errors.phone_number = "Invalid phone number"
      }

      if (
        !values.email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
        (formik.touched.email || formik.isSubmitting)
      ) {
        errors.email = "Invalid email"
      }
      if (
        ((values.notes.trim().length < 3 && values.notes.trim().length !== 0) ||
          values.notes.trim().length > 500) &&
        (formik.touched.notes || formik.isSubmitting)
      ) {
        errors.notes = "Notes should be between 3-500 characters"
      }
      if (
        values.company_type === "" &&
        (formik.touched.company_type || formik.isSubmitting)
      ) {
        errors.company_type = "Please select option"
      }

      return errors
    },
  })

  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setCookieFromUrl()
    // initializeAndTrack(location)
  }, [1])
  React.useEffect(() => {
    console.log(document.querySelectorAll("a"))
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <div style={{ marginTop: "-50px" }}>
      {success ? (
        <div className="success">
          <h1>Thank you for contacting us</h1>
          <p>
            We will get back to you soon. In the mean time you can go to
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <a
                href="/"
                className="link-style-button"
                onClick={() => {
                  setSuccess(false)
                  setError(false)
                  formik.resetForm()
                }}
              >
                home page
              </a>
            </div>
          </p>
        </div>
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="contactUsContent"
          style={{ width: "100%" }}
        >
          <div className="cardItem">
            <div>
              <div style={{ textAlign: "center" }}>
                <h4>Contact Us</h4>
              </div>

              <div className="rowItem">
                <div className="form-group-1">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                  />
                  {formik.errors.first_name && (
                    <div className="error">{formik.errors.first_name}</div>
                  )}
                </div>
                <div className="form-group-1">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                  />
                  {formik.errors.last_name && (
                    <div className="error">{formik.errors.last_name}</div>
                  )}
                </div>
              </div>
            </div>
            {/* phone number input field with +91 prefix  */}
            <div className="form-group-1">
              <label htmlFor="phone_number">Phone Number</label>
              <div className="input-prefix-item">
                <span className="input-prefix-item-phone-number-icon">+91</span>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  className="input-prefix-item-input"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </div>
              {formik.errors.phone_number && (
                <div className="error">{formik.errors.phone_number}</div>
              )}
            </div>
            <div className="form-group-1">
              <label htmlFor="email">Official Email</label>
              <input
                type="text"
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
              )}
            </div>
            <div className="form-group-1">
              <label htmlFor="company_type">You are</label>
              <select
                name="company_type"
                id="company_type"
                onChange={formik.handleChange}
                value={formik.values.company_type}
              >
                <option selected value="">
                  -- select an option --
                </option>
                <option value="brand">Brand</option>
                <option value="agency">Agency</option>
                <option value="influencer">Influencer</option>
              </select>
              {formik.errors.company_type && (
                <div className="error">{formik.errors.company_type}</div>
              )}
            </div>

            <div className="form-group-1">
              <label htmlFor="notes">Your Requirements</label>
              <textarea
                name="notes"
                id="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
              {formik.errors.notes && (
                <div className="error">{formik.errors.notes}</div>
              )}
            </div>
            <div className="form-group-1">
              <button
                type="submit"
                className={`contactUsBtn ${loading ? "contact-disabled" : ""}`}
                onClick={() => {
                  formik.setSubmitting(true)
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {loading && <div className="loading-circular"></div>}
                  Submit
                </div>
              </button>
              {error && (
                <div className="error">Something went wrong, Try again.</div>
              )}
            </div>
          </div>
        </form>
      )}
      {/* </div> */}
    </div>
  )
}
