import * as React from "react"
import Bullets from "../img/bullets.svg"

export default function BulletElement({
  title,
  description,
}: {
  title: string
  description: string
}) {
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <div>
        <Bullets />
      </div>
      <div style={{ marginLeft: 20 }}>
        <strong>{title}</strong>
        <span>{description}</span>
      </div>
    </li>
  )
}
